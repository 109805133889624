import React from "react"
import { logoGrayReversed, secondaryLogoWhite } from "../utils/images"
import { Container, Row, Col } from "react-bootstrap"
import EmailForm from "../components/emailForm"
import { AiFillTwitterCircle } from "react-icons/ai"
import { AiFillInstagram } from "react-icons/ai"
import { FaFacebook } from "react-icons/fa"
import * as styles from "./footer.module.css"

const Footer = () => {

  return (
    <Container fluid className={styles.footer}>
      <Row>
        <Col xs={12} lg={4}>
          <a href="/#1/1">
            <img
              src={secondaryLogoWhite}
              alt="SEIU-UHW logo"
              width="240px"
            ></img>
          </a>
        </Col>
        <Col xs={12} lg={8} style={{ textAlign: "right" }}>
          <div className={styles.footerLinks}>
            <a href="/their-stories/#1">
              <h3>Their Stories</h3>
            </a>
            {/* <a href="/their-names/#1">
              <h3>Their Names</h3>
            </a> */}
            {/* <a href="/events/#1">
              <h3>Events</h3>
            </a> */}
            <a href="/remembrance/#2">
              <h3>Add to Memorial</h3>
            </a>
            {/* <a href="/remembrance/#1">
              <h3>Month of Remembrance</h3>
            </a> */}
            <h3>
              <a
                href="https://www.facebook.com/ForeverEssentialMemorial"
                target="__blank"
              >
                <FaFacebook size="30px" />
              </a>
              <a href="https://twitter.com/freveressential" target="__blank">
                <AiFillTwitterCircle
                  size="33px"
                  style={{ marginLeft: "15px" }}
                />
              </a>
              <a href="https://www.instagram.com/frveressential/" target="__blank">
                <AiFillInstagram
                  size="37px"
                  style={{ marginLeft: "11px" }}
                />
              </a>
            </h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} xl={4}>
          <EmailForm />
          <p>
            Proudly brought to you by the hardworking healthcare workers of
            SEIU-UHW.
          </p>
        </Col>
        <Col
          xs={12}
          md={6}
          xl={{ offset: "4", span: "4" }}
          className={styles.imageLogo}
        >
          <a href="/about-seiu-uhw/#1">
            <img
              src={logoGrayReversed}
              alt="SEIU-Page"
              width="240px"
            ></img>
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
