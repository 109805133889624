import React from "react"
import FullScreenMenu from "./fullscreenMenu"
import Footer from "./footer"
import ReactFullpage from "@fullpage/react-fullpage"

const pluginWrapper = () => {
  require("../utils/fullpage.fadingEffect.min.js")
  require("../utils/fullpage.scrollHorizontally.min.js")
  require("../utils/fullpage.scrollOverflowReset.min.js")
}
const fullPageKey = process.env.GATSBY_FULLPAGE || "3B8BFC02-F9A64871-A8C51A26-99A50085"
const fpScrollHorizonKey = process.env.GATSBY_FULLPAGE_HORIZON || "bmV0bGlmeS5hcHBfT0UzYzJOeWIyeHNTRzl5YVhwdmJuUmhiR3g1blE1"
const fpFadingKey = process.env.GATSBY_FULLPAGE_FADING || "bmV0bGlmeS5hcHBfallkWm1Ga2FXNW5SV1ptWldOMGhscA=="
const fpOverflowKey = process.env.GATSBY_FULLPAGE_OVERFLOW || "bmV0bGlmeS5hcHBfVUs1YzJOeWIyeHNUM1psY21ac2IzZFNaWE5sZEE9PWhwdg=="

var g_interval;

const Layout = (props) => (
  <div>
    <header>
      <FullScreenMenu />
    </header>
    <ReactFullpage
      pluginWrapper={pluginWrapper}
      //fullpage options
      anchors={["1","2","3","4","5","6","7","8","9","10"]}
      licenseKey={fullPageKey}
      scrollingSpeed={2500} /* Options here */
      scrollOverflow={true}
      scrollOverflowReset={true}
      scrollOverflowOptions= {{
        click: false,
        preventDefaultException: { tagName:/.*/ }
      }}
      scrollOverflowResetKey={fpOverflowKey}
      onLeave={function (origin, destination, direction) {
        if (props.handlerDestination) {
          props.handlerDestination(destination.index)
        }
        var indexSections = document.getElementsByClassName("index_section");
        var theirStoriesSections = document.getElementsByClassName("theirstories_section");
        var theirNamesSections = document.getElementsByClassName("theirnames_section");
        var aboutSEIU_Sections = document.getElementsByClassName("aboutseiu_section");
        var workerSections = document.getElementsByClassName("worker_section");
        let dataLayer = window && window.dataLayer;
        var aboutSections = document.getElementsByClassName("about_section");
        if (direction === "down" && dataLayer) {
          if (indexSections.length > 0) {
            window.dataLayer.push({'event': 'section_scroll', 'section_scroll': 'index_' + destination.index});
          }
          if (theirStoriesSections.length > 0) {
            window.dataLayer.push({'event': 'section_scroll', 'section_scroll': 'theirstories_' + destination.index});
          }
          if (theirNamesSections.length > 0) {
            window.dataLayer.push({'event': 'section_scroll', 'section_scroll': 'theirnames_' + destination.index});
          }
          if (aboutSEIU_Sections.length > 0) {
            window.dataLayer.push({'event': 'section_scroll', 'section_scroll': 'aboutseiu__' + destination.index});
          }
          if (workerSections.length > 0) {
            window.dataLayer.push({'event': 'section_scroll', 'section_scroll': 'worker_' + destination.index});
          }
          if (aboutSections.length > 0) {
            window.dataLayer.push({'event': 'section_scroll', 'section_scroll': 'about__' + destination.index});
          }
        }
      }}
      // onLeave={function (origin, destination, direction) {
      //   var sections = document.getElementsByClassName("index_section")
      //   for (var i = 0; i < sections.length; i++) {
      //     sections[i].classList.remove("show_transition")
      //     sections[i].classList.add("hide_transition")
      //   }
      //   if (sections[destination.index]) {
      //     sections[destination.index].classList.add("show_transition")
      //   }
      // }}
      afterLoad={function (origin, destination, direction) {
        clearInterval(g_interval);
        // 1000 milliseconds lapse
        const lapse = 4000;    
        const hasSlides = destination.item.querySelectorAll('.fp-slides').length;
        if(destination.isFirst && props.handlerDestination) {
          props.handlerDestination(destination.index)
        }
        if(hasSlides){
          g_interval = setInterval(function () {
            window.fullpage_api.moveSlideRight();
          }, lapse);
        }
      }}
      autoScrolling={true}
      scrollHorizontallyKey={fpScrollHorizonKey}
      scrollHorizontally={true}
      fadingEffectKey={fpFadingKey}
      fadingEffect="slides"
      controlArrows={false}
      keyboardScrolling= {true}
      loopHorizontal= {false}
      animateAnchor= {false}
      easingcss3= {"cubic-bezier(1.000, 0.010, 0.705, 0.775)"}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <main>
              {props.children}
              <div className="section fp-auto-height">
                <Footer />
              </div>
            </main>
          </ReactFullpage.Wrapper>
        )
      }}
    />
  </div>
)

export default Layout
