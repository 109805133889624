import React from "react"
import arrow from "../images/arrow.svg"
import arrowBlack from "../images/arrow-black.svg"

const Arrow = ({ direction, width, black, ...props }) => {
  let rotate
  switch (direction) {
    case "down":
      rotate = "none"
      break
    case "up":
      rotate = "rotate(180deg)"
      break
    case "left":
      rotate = "rotate(90deg)"
      break
    case "right":
      rotate = "rotate(-90deg)"
      break
    default:
      break
  }
  return (
    <img
      className="arrow"
      style={{ transform: rotate}}
      src={black? arrowBlack : arrow}
      alt="arrow"
      width={width}
      {...props}
    ></img>
  )
}

Arrow.defaultProps = {
  width: "30px",
  direction: "down",
  color: "white"
}

export default Arrow
