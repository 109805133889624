import React from "react"
import { useState } from "react"
import * as styles from "./forms.module.css"
import Arrow from "./arrow"
import { Container, Row, Col } from "react-bootstrap"

const EmailForm = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [submitStatus, setSubmitStatus] = useState(false)
  const formEndpoint = "https://seiuuhw.tfaforms.net/responses/processor"

  const submitForm = () => {
    // Trying to avoid double clicks here.
    if (submitStatus === "loading" || !(firstName || lastName || email)) {
      return // don't send this twice.
    }
    var formData = new FormData()
    formData.append("tfa_12", firstName)
    formData.append("tfa_13", lastName)
    formData.append("tfa_15", email)
    formData.append("tfa_16", "7016S000001nNUmQAM")
    formData.append("tfa_dbFormId", 170)
    formData.append("tfa_dbVersionId", 12)
    formData.append("tfa_dbControl", "6d9c5a04341c4dd7e403544f90ea43f9")

    fetch(formEndpoint, {
      method: "POST",
      mode: 'no-cors',
      body: formData,
    })
    .then(function (res) {
        setSubmitStatus("success")
    }, function (e) {
      setSubmitStatus("error")
    });
  }
  // Renders the comment form elements.
  const renderCommentForm = (
    <Container fluid>
      <form
        onSubmit={e => {
          e.preventDefault()
          setSubmitStatus("loading")
        }}
      >
          <input type="hidden" name="botField" />
          <Row>
            <Col xs="12" md="6" className={styles.field}>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                placeholder="First Name"
                required
              />
            </Col>
            <Col xs="12" md="6" className={styles.field}>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                placeholder="Last Name"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6" className={styles.field}>
              <input
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Your Email"
                required
              />
            </Col>
            <Col xs="12" md="6" className={styles.field}>
              <div className={`${styles.field} ${styles.submitButton}`}>
                <input
                  type="submit"
                  value="Stay Updated"
                  className="special"
                  onClick={submitForm}
                />
                <span style={{ padding: "6px 25px 0" }}>
                  <Arrow direction="right" onClick={submitForm} width="20px" />
                </span>
              </div>
            </Col>
          </Row>
      </form>
    </Container>
  )

  switch (submitStatus) {
    case "success": // A successful submission.
      return (
        <div style={{ textAlign: "center" }}>
          Your message has been successfully submitted.
        </div>
      )
    case "loading": // Just submitted, no response yet.
      return (
        <div style={{ textAlign: "center" }}>
          Please wait. Your message is being submitted.
        </div>
      )
    case "error": // Something went wrong.
      return (
        <div style={{ textAlign: "center" }}>
          There was an error in your submission. Please try again later.
        </div>
      )
    default:
      // No submission, render the form.
      return renderCommentForm
  }
}

export default EmailForm
