import React, { useState } from "react"
import { MdMenu } from "react-icons/md"
import { AiFillTwitterCircle } from "react-icons/ai"
import { AiFillInstagram } from "react-icons/ai"
import { FaFacebook } from "react-icons/fa"
import { useTransition, animated, config } from "react-spring"
import * as styles from "./fullscreenMenu.module.css"
import MainLogo from "../../content/assets/ForeverEssential-SecondaryLogo.png"
import Arrow from "./arrow.js"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { Container, Row, Col } from "react-bootstrap"
// import Music from "./music.js"

const FullScreenMenu = () => {
  const [isOpen, setIsOpen] = useState(false)

  const fullscreenMenu = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: "translate3d(-50%,0,0)",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
      background: "#142433",
      position: "fixed",
      height: "100vh",
      maxHeight: "100vh",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 40,
    },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
    config: config.slow,
  })

  const openButton = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      position: "absolute",
    },
    enter: {
      opacity: 1,
    },
    leave: { opacity: 0, transform: "scale(0)" },
    config: config.molasses,
  })

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  const isTablet = useMediaQuery({
    query: "(max-width: 64rem)",
  })

  return (
    <div>
      {/* <Music /> */}
      <div>
        <MediaQuery maxWidth="42rem">
          <div>
            {openButton.map(({ item, key, props }) =>
              !item ? (
                <animated.div
                  key={key}
                  style={props}
                  className={styles.menuContainer}
                >
                  <button
                    onClick={toggleMenu}
                    style={{ position: "absolute", right: "10px", top: "10px" }}
                  >
                    <MdMenu className={styles.styleToggleButton} />
                  </button>
                </animated.div>
              ) : (
                <animated.div
                  key={key}
                  style={props}
                  className={styles.menuContainer}
                >
                  <button
                    onClick={toggleMenu}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      background: "none",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        visibility: isTablet ? "" : "hidden",
                      }}
                    >
                      <Arrow width="30px" direction="left" />
                    </div>
                  </button>
                </animated.div>
              )
            )}
          </div>
        </MediaQuery>
        <MediaQuery minWidth="43rem">
          <div>
            {openButton.map(({ item, key, props }) =>
              !item ? (
                <animated.div
                  key={key}
                  style={props}
                  className={styles.menuContainer}
                >
                  <a href="/#1">
                    <button>
                      <img
                        className={styles.logoButton}
                        src={MainLogo}
                        alt="SEIU Memorial Main Logo"
                      ></img>
                    </button>
                  </a>
                  <button
                    onClick={toggleMenu}
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "-1px",
                      padding: "0 6px",
                    }}
                  >
                    <MdMenu className={styles.styleToggleButton} />
                  </button>
                </animated.div>
              ) : null
            )}
          </div>
        </MediaQuery>
      </div>
      <div>
        {fullscreenMenu.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} style={props}>
                <Container fluid style={{ padding: "0 7%" }}>
                  <Row
                    style={{
                      height: isTablet ? "100%" : "100vh",
                      alignItems: "center",
                    }}
                  >
                    <Col xs="12" lg="8" xl="7">
                      <a href="/#1" onClick={toggleMenu}>
                        <img
                          className={styles.logoButton}
                          src={MainLogo}
                          alt="SEIU Memorial Main Logo"
                          style={
                            isTablet
                              ? { marginBottom: "10px" }
                              : {
                                  position: "fixed",
                                  top: "10px",
                                  marginBottom: "10px",
                                }
                          }
                        ></img>
                      </a>
                      {/* <h1
                        style={{
                          margin: isTablet ? "30px 0 0" : "60px 0 0",
                          fontSize: isTablet ? "2rem" : "5rem",
                        }}
                      >
                        <a href="/#1/1" onClick={toggleMenu}>
                          Home
                        </a>
                      </h1> */}
                      <h1
                        style={{
                          margin: "0",
                          fontSize: isTablet ? "2rem" : "5rem",
                        }}
                      >
                        <a href="/their-stories/#1" onClick={toggleMenu}>
                          Their Stories
                        </a>
                      </h1>
                      {/* <h1
                        style={{
                          margin: "0",
                          fontSize: isTablet ? "2rem" : "5rem",
                        }}
                      >
                        <a href="/their-names/#1" onClick={toggleMenu}>
                          Their Names
                        </a>
                      </h1> */}
                      {/* <h1
                        style={{
                          margin: "0",
                          fontSize: isTablet ? "2rem" : "5rem",
                        }}
                      >
                        <a href="/events/#1" onClick={toggleMenu}>
                          Events
                        </a>
                      </h1> */}
                      <h1
                        style={{
                          margin: "0",
                          fontSize: isTablet ? "2rem" : "5rem",
                        }}
                      >
                        <a href="/remembrance/#2" onClick={toggleMenu}>
                          Add to Memorial
                        </a>
                      </h1>
                      {/* <h1
                        style={{
                          margin: "0",
                          fontSize: isTablet ? "2rem" : "5rem",
                        }}
                      >
                        <a href="/remembrance/#1" onClick={toggleMenu}>
                          Month of Remembrance
                        </a>
                      </h1> */}
                      <h3>
                        <a
                          href="https://www.facebook.com/ForeverEssentialMemorial"
                          target="__blank"
                        >
                          <FaFacebook size="30px" />
                        </a>
                        <a
                          href="https://twitter.com/freveressential"
                          target="__blank"
                        >
                          <AiFillTwitterCircle
                            size="33px"
                            style={{ marginLeft: "15px" }}
                          />
                        </a>
                        <a
                          href="https://www.instagram.com/frveressential/"
                          target="__blank"
                        >
                          <AiFillInstagram
                            size="37px"
                            style={{ marginLeft: "11px" }}
                          />
                        </a>
                      </h3>
                      <p>
                        <a href="mailto: info@foreveressential.org">
                          <span style={{ marginRight: "15px" }}>
                            Get in touch
                          </span>
                          <Arrow width="10px" direction="right" />
                        </a>
                      </p>
                    </Col>
                    <Col xs="12" lg="4" xl="5">
                      <Container>
                        <Row style={{ justifyContent: "flex-end" }}>
                          <MediaQuery minWidth="65rem">
                            <button
                              className={styles.styleToggleButton}
                              onClick={toggleMenu}
                            >
                              <div style={{ marginRight: "10px" }}>
                                <Arrow width="50px" direction="left" />
                              </div>
                            </button>
                          </MediaQuery>
                          <MediaQuery minWidth="43rem" maxWidth="64rem">
                            <button
                              className={styles.styleToggleButton}
                              onClick={toggleMenu}
                            >
                              <div style={{ marginRight: "10px" }}>
                                <Arrow width="30px" direction="left" />
                              </div>
                            </button>
                          </MediaQuery>
                        </Row>
                        <Row style={{ justifyContent: "flex-end" }}>
                          <span
                            style={{
                              justifyContent: "center",
                              fontSize: isTablet ? "1rem" : "1.5rem",
                              paddingTop: isTablet ? "0px" : "180px",
                            }}
                          >
                            Forever Essential is a memorial that honors the
                            legacy of California healthcare workers who have
                            lost their lives to the COVID-19 pandemic.
                          </span>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </animated.div>
            )
        )}
      </div>
    </div>
  )
}

export default FullScreenMenu
