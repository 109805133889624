/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import {seoImage} from "../utils/images"

const SEO = ({ description, lang, meta, title, metaImage, fbHeadline, fbDescription, twShare, siteUrl }) => {
  const image = metaImage || seoImage
  const imageUrl = siteUrl + image;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: fbHeadline,
        },
        {
          property: `og:description`,
          content: fbDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: twShare,
        },
      ]
      .concat(
              {
                property: "og:image",
                content: imageUrl,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },       {
                name: `image`,
                content: imageUrl,
              },
              {
                name: `twitter:image`,
                content: imageUrl,
              },
              {
                property: `og:image`,
                content: imageUrl,
              },
              {
                itemprop: `image`,
                content: imageUrl,
              }
      ).concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: 'Forever Essential is a memorial that honors the legacies of California healthcare workers who have lost their lives to COVID-19.',
  title: 'Forever Essential: A Healthcare Worker Memorial',
  siteUrl: 'https://foreveressential.org',
  fbHeadline: 'Forever Essential',
  fbDescription: 'A Healthcare Worker Memorial',
  twShare: 'Throughout the COVID-19 crisis, we’ve lost many brave, dedicated healthcare workers. They all have legacies. #ForeverEssential tells their stories.'
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  fbHeadline: PropTypes.string,
  fbDescription: PropTypes.string,
  twShare: PropTypes.string
}

export default SEO
